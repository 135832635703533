.subContainer {
  padding: 140px 30px 0;
  background-image: url(../../assets/imgsDoctors/Bg\ small.png);
  background-size: cover;
  height: auto;
}
.about_content {
  /* text-align: center; */
  color: black;
  font-weight: 500;
  font-size: 1.2rem;
}
.about_content h1,
h4 {
  font-weight: bold;
  margin-right: 10px;
  color: #375f89;
}
.about_content_items {
  line-height: 1.6rem;
}
.aboutImg {
  display: flex;
}
.aboutImg img {
  width: 145%;
  position: relative;
}

.about_items {
  display: flex;

  margin-top: 1rem;
}
.about_vision h4 {
  font-weight: bold;
  margin-right: 10px;
  color: #375f89;
}
.about_list {
  padding: 0;
  margin-bottom: 0.5rem;
}
.about_list li {
  list-style: none;
}
.about_list li span {
  color: #375f89;
  font-size: 1.3rem;
}

@media (max-width: 1400px) {
  .aboutImg img {
    width: 150% !important;
    margin-top: 5rem;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .aboutImg img {
    width: 160% !important;
    margin-top: 15rem;
  }
}
@media (max-width: 992px) {
  .aboutImg img {
    width: 100% !important;
    left: auto !important;
    right: auto !important;
    margin-top: 0 !important;
  }
  .about_content h1 {
    margin-top: 2rem;
  }
  .about_content {
    text-align: center;
  }
  .about_items{
  text-align: center;
  justify-content: center;
  }
}
