/* .features {
  padding: 60px 0px 80px;
  background-image: url(../../assets/imgsDoctors/Bg\ small.png);
  background-size: cover;
}

.features h3 {
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  color: #2e5784;
  padding-right: 410px;
}

.features p {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #3a3a3a;
  letter-spacing: 0.1px;
}

.featuresContent {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  transition: 0.5s;
}

.featuresContent h3 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
  color: #fff;
}

.imgContainerFeatures {
  display: flex;
  align-items: center;
  justify-content: center;
}

.featuresContent img {
  height: 300px;
}
.imgContainerFeatures img {
  height: 50px;
  margin-left: 10px;
}
.imgContainerFeatures h3 {
  margin: 0;
}

.animations2 {
  background-color: #7b9fc7;
  z-index: -1;
}

.useContainer {
  height: 100%;
  padding: 20px 0px;
}

.useContainer p {
  margin: 0;
  color: #fff;
  margin-bottom: 50px !important;
  font-size: 33px;
}

.animations2Margin {
  margin-left: -158px;
}

.Patient1 {
  position: relative;
}

.Patients {
  margin-right: 218px;
  height: 400px !important;
}

.socialMedia {
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialMedia img {
  height: 60px;
  margin: 0 10px;
} */

/* **************************** START MEDIA QUERY ******************************* */
.appGooglePlay{
cursor: pointer;}
.modal-content{
  background-color: #7b9fc7 !important;
}
.modalComing{
  background-color: #7b9fc7 !important;
  color: #fff;
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
}
@media (min-width: 768px) {
  .featuresContent:hover img {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .features {
    margin-top: 30px;
  }
  .features h1 {
    text-transform: uppercase;
  }

  .featuresContent {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .features h3 {
    padding-right: 0px;
    width: 100% !important;
    text-align: center;
    margin-top: 20px;
  }

  .animations1 img {
    position: absolute;
    top: 0;
    left: 0;
    height: 400px;
  }

  .Patient1,
  .Patient3 {
    display: none;
  }

  .Patients {
    display: none;
  }

  .animations2Margin {
    margin-left: 0px;
  }

  .useContainer {
    /* padding: 10px; */
  }

  .useContainer p {
    font-size: 25px;
    /* margin-bottom: 20px !important; */
  }

  .socialMedia img {
    height: 40px !important;
  }

  .animations1 {
    display: none;
  }

  .useContainer {
    /* height: 100%; */
    height: auto !important;
    padding: 20px 30px;
    width: 100%;
  }

  .useContainer p {
    margin: 0;
    color: #fff;
    margin-bottom: 50px !important;
    font-size: 33px;
  }
}
/* **************************** END MEDIA QUERY ******************************* */

.features {
  padding: 60px 0px 80px;
  background-image: url(../../assets/imgsDoctors/Bg\ small.png);
  background-size: cover;
}
.features p {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #3a3a3a;
  letter-spacing: 0.1px;
}

.featuresContent {
  /* margin: auto; */
  margin-top: 20px;
  margin-left: 250px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  border-radius: 20px;
  transition: 0.5s;
  width: 45%;
}

.useContainer {
  /* height: 100%; */
  height: 250px;
  padding: 20px 30px;
  width: 100%;
}

.useContainer p {
  margin: 0;
  color: #fff;
  margin-bottom: 50px !important;
  font-size: 33px;
}

.features {
  position: relative;
}

.features h3 {
  position: absolute;
  top: 0;
  left: 37%;
  transform: translate(-37%);
  /* text-align: center; */
  font-weight: bold;
  font-size: 44px;
  color: #2e5784;
  /* padding-right: 320px; */
}

.Patient1,
.Patient3,
.Patients {
  height: 100px;
}

.socialMedia {
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialMedia img {
  height: 60px;
  margin: 0 10px;
}

.animations2 {
  background-color: #7b9fc7;
  /* z-index: -1; */
}

.featuresContent {
  position: relative;
}
.Patients {
  position: absolute;
  right: -210px;
  top: -70px;
  height: 380px !important;
}

.Patient1,
.Patient3 {
  position: absolute;
  left: -160px;
  top: -70px;
  height: 400px;
}

.Patient3 {
  left: -230px;
}
