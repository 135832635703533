.features {
  padding: 100px 0px 0px;
  background-image: url(../../assets/imgsDoctors/Bg\ small.png);
  background-size: cover;
}

.features h3 {
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  color: #2e5784;
}

.features p {
  text-align: center;
  font-size: 25px;
  /* font-weight: 600; */
  color: #3a3a3a;
  letter-spacing: 0.1px;
}

.featuresContent {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* align-items: center; */
  padding: 40px 25px;
  border-radius: 5px;
  transition: 0.5s;
  /* height: 280px; */
  height: 300px;
  border: 1px solid #ddd;
}

.featuresContent:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.imgContainerFeatures {
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
}

.imgContainerFeatures img {
  height: 40px;
  transition: 0.5s;
  margin-left: 5px;
}

.imgContainerFeatures h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.featuresContent p {
  padding: 0;
  margin: 0;
  /* font-weight: bold; */
  color: #333;
  font-size: 18px;
  /* width: 85%; */
  text-align: initial;
  letter-spacing: .3px;
}

/* **************************** START MEDIA QUERY ******************************* */
@media (min-width: 768px) {
  /* .featuresContent:hover {
    box-shadow: 3px 3px 20px 0px rgb(230 230 230);
    transform: translateY(-5px);
  } */
  .featuresContent:hover img {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .features h1 {
    text-transform: uppercase;
  }

  .featuresContent {
    margin-top: 40px;
    height: 340px;
  }
}
/* **************************** END MEDIA QUERY ******************************* */
.featuresContent {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s;
}

.animations1,
.animations2,
.animations3,
.animations4,
.animations5,
.animations6 {
  transform: translateY(0px);
  opacity: 1;
  /* transition: 0.3s; */
}

.animations1 {
  transition-delay: 0.1s;
}
.animations2 {
  transition-delay: 0.2s;
}
.animations3 {
  transition-delay: 0.3s;
}
.animations4 {
  transition-delay: 0.4s;
}
.animations5 {
  transition-delay: 0.5s;
}
.animations6 {
  transition-delay: 0.6s;
}
