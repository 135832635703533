.privacyContainer {
  padding: 50px 70px;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 40px;
}

.imgContainer img {
  width: 10%;
}

.imgContainer h1 {
  text-decoration: underline;
  font-weight: bold;
}
.terms_button {
  text-align: center;
  margin: 1rem auto;
}
.terms_button button {
  background-color: #f5f5f5;
  margin: 0.5rem;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 30px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.1rem;
}
.terms_button button:hover {
  background-color: #ddd;
}
.terms_content {
  border-bottom: 3px solid #5785b8;
  padding: 15px 0px;
}
.terms_content h5 {
  font-weight: 900;
  margin: 2rem auto;
}
.terms_content p {
  font-size: 1.1rem;
  line-height: 1.7rem;
}
@media screen and (max-width: 769px) {
  .privacyContainer {
    padding: 50px 10px;
  }
}
