.navbar {
  transition: 0.3s;
  background-color: #7b9fc7 !important;
  padding: 0px 20px !important;
}

.navBackground.navbar {
  background-color: #7b9fc7 !important;
  transition: 0.3s;
  box-shadow: 0px -4px 25px 1px #2b3336f7;
  padding: 0px 20px;
}

.logo {
  height: 60px;
  transition: 0.5s;
}

.nav {
  margin-left: 8px;
  width: 100%;
}

.nav a {
  margin: 0px 6px;
  /* color: #476C93!important; */
  color: #fff !important;
  font-weight: 600;
}

.navBackground.navbar .nav a {
  color: #fff !important;
}

.navBackground.navbar .nav a:hover {
  color: #f1f1f1 !important;
}

.nav a:hover {
  color: #f1f1f1 !important;
}

.button1 {
  background-color: #5785b8;
  padding: 0px !important;
  color: #fff !important;
  text-align: center;
  font-size: 14px;
  width: 12%;
  padding: 5px 0px !important;
  border-radius: 15px;
  border: 1px solid #5785b8;
  letter-spacing: 0.2px;
  font-weight: 600;
  transition: 0.3s;
  background: #5785b8;
  color: #fff;
  box-shadow: none;
  border: 1px solid #fff;
  cursor: pointer;
}

.button1:hover {
  color: #fff !important;
  background: #7da1c9;
}

@media screen and (max-width: 820px) {
  .navbar {
    padding: 20px 10px;
    background-color: #2b3336f7 !important;
  }

  .navbarToggle {
    border: none;
    background-color: #476c93 !important;
    padding: 4px 9px !important;
  }

  .navbarToggle:focus {
    box-shadow: none !important;
  }

  .nav {
    margin-left: 0px;
  }

  .nav a:first-child {
    margin-top: 50px !important;
  }

  .nav a {
    margin: 0px !important;
    margin-bottom: 32px !important;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #fff !important;
  }

  .navBackground.navbar {
    padding: 0px 20px !important;
    box-shadow: none;
  }

  .button1 {
    background-color: #476c93;
    color: #fff !important;
    text-align: center;
    font-size: 14px;
    width: 50%;
    padding: 10px 10px !important;
    border-radius: 8px;
    margin-bottom: 10px;
    margin: auto;
    display: block;
  }
}

.btnLang {
  background: none;
  outline: none;
  border: none;
}
