.footerContainer {
  background: #6993c0;
  padding: 20px 30px;
}
.footer__items {
  display: flex;
  justify-content: space-between;
}
.footer__link a {
  color: #333;
  text-decoration: none;
  margin-right: 4rem;
  cursor: pointer;
}
.copyRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyRight p {
  margin: 0;
  font-weight: 500;
}

.copyRight a {
  color: #333;
}

.media img {
  margin: 0 8px;
}

@media (max-width: 768px) {
  .footerContainer {
    background: #6993c0;
    padding: 10px 20px;
  }

  .copyRight {
    flex-direction: column;
  }

  .copyRight p {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 20px;
  }

  .copyRight a {
    color: #f1f1f1;
  }
  .footer__items {
    display: block;
    text-align: center;
  }
  .footer__link a {
    display: block;
 
  }
}
