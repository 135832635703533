@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Lato", sans-serif !important; */
  /* font-family: Verdana; */
  /* font-family: 'Comfortaa', cursive !important;  */
  /* font-family: "Lora", serif !important; */
  letter-spacing: 0.3px;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
/* ********************Start scrollbar************************ */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-button {
  width: 8px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #eee;
  border: thin solid lightgray;
  box-shadow: 0px 0px 3px #dfdfdf inset;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #5785b8;
  border: thin solid gray;
  border-radius: 10px;
  -webkit-transition: background 1s;
  transition: background 1s;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #7da1c9;
}
/* ********************End scrollbar************************ */
.backgroundIMG {
  background-image: url(./assets/imgsDoctors/Bg\ small.png);
  background-size: cover;
  height: 100vh;
}

.ant-collapse-header-text {
  font-size: 18px;
  color: #333;
  font-weight: 500;
}

.ant-collapse-item p {
  margin: 0;
  font-size: 17px;
  color: #7965ab;
  font-weight: 500;
}
.progress-bar {
  background-color: #fff !important;
  border-radius: 10px;

}
.modal-content {
  background-color: #7b9fc7 !important;
  border-radius: 2rem !important;
}
.progress {
  background-color: #a8c0da !important;
  border-radius: 10px;
  border: 1px solid #fff;
  margin: 1rem 0;
  border-radius: 10px !important;
}
