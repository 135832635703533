.subContainer {
  padding: 140px 30px;
  background-image: url(../../assets/imgsDoctors/Bg\ small.png);
  background-size: cover;
  height: 100vh;
}

.contactLOGO {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.contactLOGO h1 {
  font-weight: bold;
  margin-right: 10px;
  color: #375F89;
}

.contactLOGO img {
  height: 45px;
}

.footerForm {
  background-color: #c8d7e8;
  padding: 20px;
  border-radius: 50px;
  width: 70%;
  margin: auto;
}

.footerForm h3 {
  font-size: 20px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.footerForm input,
.footerForm textarea {
  margin-bottom: 20px;
  padding: 6px 10px;
  outline: none;
  border-radius: 15px;
  border: none;
  width: 100%;
  resize: none;
  border: 1px solid #7965ab4f;
}

.footerForm input::placeholder,
.footerForm textarea::placeholder {
  font-size: 15px;
  color: #6b93c0;
}

.footerForm textarea {
  margin-bottom: 10px;
  height: 120px;
}

.footerForm .btn {
  background-color: #6b93c0;
  border: none;
  outline: none;
  padding: 12px 28px;
  border-radius: 7px;
  color: #f1f1f1;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin: auto;
  display: block;
}

.contactUsIcon img {
  width: 100%;
}

@media screen and (max-width: 769px) {
  .subContainer {
    padding: 140px 0px;
  }
  .footerForm {
    width: 100%;
  }
}
