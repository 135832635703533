.plans {
  padding: 80px 35px 100px;
  background-image: url(../../assets/icon/backgroundfull.png);
  background-size: cover;
}

.plans h3 {
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  margin: 0;
  margin-bottom: 10px;
}

.plans p {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #525252;
  letter-spacing: 0.1px;
}

/* ***********allFeature*************** */
.allFeature {
  border: 2px solid #5a468d;
  border-radius: 10px;
  position: relative;
  margin-top: 313px;
}

.allFeature img {
  position: absolute;
  width: 185px;
  top: -120px;
  right: 23%;
}

.allFeature h2 {
  margin-top: 100px;
  margin-bottom: 0;
  text-align: center;
  color: #1e1a4d;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 3px;
  font-weight: bold;
}

.border {
  border-bottom: 3px solid #1e1a4d;
  margin: auto;
  margin-top: 6px;
  width: 65%;
  margin-bottom: 20px;
}

.allFeature ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.allFeature ul li {
  color: #5a468d;
  padding: 3px 10px;
  margin: 0px 10px;
  font-weight: 500;
  font-size: 15px;
  border-bottom: 1px solid #f17e83;
  display: flex;
  align-items: center;
}

.allFeature ul li:last-child {
  border-bottom: none;
}

.toltipBtn {
  margin-left: 0px !important;
  background: none !important;
  color: #333 !important;
  border: none !important;
}

.mark {
  /* font-size: 15px; */
  margin-top: -3px;
}

/* *********************plans********************** */
.imgContainer img {
  width: 100%;
}

.setupFees {
  border: 2px solid #f17e83;
  padding: 5px;
  border-radius: 10px;
  margin-top: 2px;
  font-weight: bold;
  color: #5a468d;
  font-size: 17px;
}

.plan,
.plan2,
.plan3 {
  padding: 5px;
  margin-top: 10px;
  background: #f9e3cb;
  border-radius: 10px;
  text-align: center;
}

.plan2 {
  background-color: #b7b3e1;
}

.plan3 {
  background-color: #fac3dd;
}

.plan h6,
.plan h4 {
  color: #de1412;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
}

.plan h4 {
  font-size: 19px;
  margin-bottom: 5px;
}

.plan h5 {
  margin: 0;
  color: #1e1a4d;
  font-weight: bold;
  font-size: 18px;
}

.plan span {
  color: #1e1a4d;
  font-weight: bold;
  font-size: 18px;
}

.or {
  margin-bottom: 5px;
  font-size: 19px;
}

.noCommission,
.yourDomain {
  padding: 5px;
  text-align: center;
  color: #fff;
  background: #f17e83;
  border-radius: 10px;
  margin: 2px 0px;
  font-weight: bold;
  letter-spacing: 3px;
}

.security {
  padding: 5px;
  text-align: center;
  color: #f17e83;
  border-radius: 10px;
  margin: 4px 0px;
  font-weight: bold;
  letter-spacing: 3px;
  border: 1px solid #f17e83;
}

.borderPlans {
  padding: 0px 5px;
  border: 2px solid #5a468d;
  border-top: none;
  border-radius: 0px 0px 10px 10px;
  height: 100%;
}

.planMap p {
  font-size: 16px;
  margin: 0px 0;
  padding: 0.8px 0px;
  text-align: left;
}

.imgContainerIcon,
.imgContainerIconRight,
.detailsPlans {
  /* background: #f9e3cb; */
  border-radius: 10px;
  margin: 5px 0px;
  padding: 7px 10px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.imgContainerIcon img,
.imgContainerIconRight img {
  width: 23px;
  height: 23px;
  margin: 0px auto;
  display: block;
}

.detailsPlans {
  font-weight: bold;
  color: #333;
  text-align: center;
  /* font-size: 18px; */
}

.SubscribeBtn {
  border: none;
  outline: none;
  background-color: #7965ac;
  width: 100%;
  color: #fff !important;
  margin: 10px 0px 5px;
  padding: 5px 0px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 17px;
}

.SubscribeBtn a {
  color: #fff !important;
  text-decoration: none;
  width: 100%;
  display: block;
}

/* planCustomized */

.planCustomized {
  padding: 5px;
  margin-top: 10px;
  background-color: #1e1a4d;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 30px;
}

.allFeatures {
  padding: 5px;
  background-color: #1e1a4d;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  letter-spacing: 3px;
}

.showIMGSContainer {
  border: 2px solid #5a468d;
  border-top: 0px;
  margin: 5px 0;
  height: 100%;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 5px;
}

.showIMGS {
  /* border: 2px solid #5a468d;
  border-bottom: 0px;
  border-top: 0px;
  margin: 5px 0;
  height: 99%;
  border-radius: 0px 0px 10px 10px;
  */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.showIMGS img {
  width: 80%;
}

/* edit */
.planMap:nth-child(even) {
  background-color: #f9e3cb;
  border-radius: 20px;
}

.planMap2:nth-child(even) {
  border-radius: 20px;
  background-color: #b7b3e1;
}

.planMap3:nth-child(even) {
  border-radius: 20px;
  background-color: #fac3dd;
}

.planMap4:nth-child(odd) {
  background-color: #f9e3cb;
  border-radius: 20px;
}

.planMap5:nth-child(odd) {
  border-radius: 20px;
  background-color: #b7b3e1;
}

.planMap6:nth-child(odd) {
  border-radius: 20px;
  background-color: #fac3dd;
}

.paypalLogo {
  width: 12%;
}
.GeideaLogo {
  width: 11%;
}

.paymobLogo {
  width: 25%;
}

@media screen and (max-width: 1400px) {
  .allFeature {
    margin-top: 270px;
  }

  .allFeature li {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 1430px) and (max-width: 1500px) {
  /* For landscape layouts only */
  .allFeature {
    margin-top: 290px;
  }

  .allFeature li {
    font-size: 13px !important;
  }
}

.setupFeesEdit {
  background-color: #de1412;
  border: 2px solid #f17e83;
  padding: 2px 8px;
  border-radius: 10px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.feesContainer{
  width: 27%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.feesContainer span{
  font-weight: bold;
  font-size: 17px;
  color: #f4f4f4;
}

.setupFeesEdit img {
  width: 9%;
}
