.features {
  padding: 60px 0px;
  background-image: url(../../assets/imgsDoctors/Bg\ small.png);
  background-size: cover;
}

.features h3 {
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  color: #2e5784;
}

.features p {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #3a3a3a;
  letter-spacing: 0.1px;
}

.featuresContent {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  align-items: center;
  padding: 20px 10px;
  border-radius: 20px;
  transition: 0.5s;
  background-color: #7b9fc7;
}

.featuresContent:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.featuresContent h3 {
  font-size: 19px;
  font-weight: bold;
  margin: 0;
  color: #fff;
  height: 70px;
}

.imgContainerFeatures {
  display: flex;
  align-items: center;
  justify-content: center;
}

.featuresContent img {
  height: 320px;
  width: 100%;
  padding: 5px;
}

/* **************************** START MEDIA QUERY ******************************* */
@media (min-width: 768px) {
  .featuresContent:hover img {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .features h1 {
    text-transform: uppercase;
  }

  .featuresContent {
    margin-top: 40px;
    /* height: 240px; */
  }
}
/* **************************** END MEDIA QUERY ******************************* */
