/* *****************START GLOBAL***************** */
.flexItem {
  display: flex;
  /* align-items: center; */
}
/* *****************END GLOBAL***************** */
.header {
  padding: 100px 30px 40px;
  height: 100vh;
}

.headerContent p {
  font-size: 42px;
  font-weight: bold;
  width: 116%;
  margin: 0;
  padding-top: 70px;
}

.headerContent .minutes {
  color: #5785B8;
}

.headerContent .paragraph {
  color: #484848;
  font-size: 24px;
  font-weight: 400;
}

.buttons {
  margin-top: -80px !important;
}

.buttons button {
  border: none;
  outline: none;
  padding: 8px 35px;
  background-color: #7965ab;
  color: #fff;
  border-radius: 20px;
  border: 1px solid rebeccapurple;
  letter-spacing: 0.2px;
  font-weight: 600;
  transition: 0.3s;
}

.buttons button:hover {
  background: none;
  color: #484848;
  box-shadow: 5px 5px 10px 0px #e2e2e2;
}


.headerImage img {
  width: 100%;
  transition: 0.3s;
  /* transform: scale(1.1); */
}

/* **************************** START MEDIA QUERY ******************************* */
@media (max-width: 768px) {
  .header {
    padding-top: 80px;
    height: 60vh;
  }

  .headerContent {
    text-align: center;
  }

  .headerContent p {
    font-size: 25px;
    width: 100%;
    font-weight: bold;
  }

  .headerContent .paragraph {
    color: #585858;
    font-size: 20px;
    font-weight: 400;
  }

  .headerImage {
    margin-top: 40px;
  }

  .buttons {
    margin-top: 30px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
/* **************************** END MEDIA QUERY ******************************* */
