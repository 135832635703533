.subContainer {
  padding: 140px 30px 0;
  background-image: url(../../assets/imgsDoctors/Bg\ small.png);
  background-size: cover;
  min-height: auto;
}
.pricing_content {
  height: auto;
}
.pricing_content h1 {
  font-weight: bold;
  /* margin-right: 10px; */
  color: #375f89;
}
.pricing_content p {
  font-size: 1.2rem;
  margin-top: 2rem;
}
.pricing_img {
  display: flex;
}
.pricing_img img {
  width: 125%;
  position: relative;
  /* right: 170px; */
  margin-top: 15rem;
}
.pricing_items {
  z-index: 1;
}
.payment img {
  margin: 2rem .5rem;
  width: 100px;
}
@media (max-width: 1000px) {
  .pricing_img img {
    width: 100%;
    position: relative;
    right: auto !important;
    left: auto !important;
    margin-top: 0;
  }
  .pricing_items {
    /* margin-bottom: -7rem; */
    z-index: 1;
  }
}
