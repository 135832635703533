.features {
  padding: 100px 0px;
  background-image: url(../../assets/imgsDoctors/Bg\ small.png);
  background-size: cover;
}

.features h3 {
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  color: #2e5784;
}

.features p {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #3a3a3a;
  letter-spacing: 0.1px;
}

.featuresContent {
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  transition: 0.5s;
  width: 50%;
}

.featuresContent h3 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
  color: #fff;
}

.imgContainerFeatures {
  display: flex;
  align-items: center;
  justify-content: center;
}

.featuresContent img {
  height: 300px;
}
.imgContainerFeatures img {
  height: 50px;
  margin-left: 10px;
}
.imgContainerFeatures h3 {
  margin: 0;
}

.animations2 {
  background-color: #7b9fc7;
  /* z-index: -1; */
}

.useContainer {
  /* height: 100%; */
  height: 249px;
  padding: 20px;
}

.useContainer p {
  margin: 0;
  color: #fff;
  margin-bottom: 50px !important;
  font-size: 33px;
}
.appGooglePlay{
  cursor: pointer;
}
.modal-content{
  background-color: #7b9fc7 !important;
}
.modalComing{
  background-color: #7b9fc7 !important;
  color: #fff;
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
}

/* .Patient1 {
  position: relative;
  margin-left: 315px;
} */

.featuresContent {
  position: relative;
}

.Patients {
  position: absolute;
  right: -220px;
  top: -60px;
  height: 350px !important;
  /* margin-right: 235px; */
  /* height: 400px !important; */
}

.Patient1 {
  position: absolute;
  left: -190px;
  top: -51px;
  /* height: 350px !important; */
}

.socialMedia {
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialMedia img {
  height: 60px;
  margin: 0 10px;
}

/* **************************** START MEDIA QUERY ******************************* */
@media (min-width: 768px) {
  .featuresContent:hover img {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .features {
    padding: 0px 0px;
  }

  .features h1 {
    text-transform: uppercase;
  }
  
  .featuresContent {
    margin-top: 40px;
    /* height: 240px; */
    width: 100%;
  }
  
  .features h3 {
    margin-bottom: 0px;
    margin-bottom: 15px;
  }

  .Patient1 {
    margin-left: 0px;
    display: none;
  }

  .Patients {
    display: none;
  }

  .useContainer {
    /* padding: 10px; */
  }

  .useContainer p {
    font-size: 25px;
    /* margin-bottom: 20px !important; */
  }

  .socialMedia img {
    height: 40px;
  }
}
/* **************************** END MEDIA QUERY ******************************* */
