.privacyContainer {
  padding: 50px 70px;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 40px;
}

.imgContainer img {
  width: 10%;
}

.imgContainer h1 {
  text-decoration: underline;
  font-weight: bold;
}

.privacyContainer p {
  margin: 0;
  padding: 0;
}

.div {
  border-bottom: 3px solid #5785b8;
  padding: 15px 0px;
}

.div:last-of-type {
  border-bottom: 0px;
}

.div p {
  margin: 10px 0px;
  /* font-weight: bold; */
  color: #333;
  font-size: 18px;
}

.div h1 {
  font-weight: bold;
  font-size: 30px;
}

.div span {
  font-weight: 500;
  color: #222;
}
@media screen and (max-width: 769px) {
  .privacyContainer {
    padding: 50px 10px;
  }
}
