.helpContainer {
  padding: 150px 30px 40px;
}

.helpContent {
  background: #f1f0f7;
  border-radius: 20px;
  padding: 20px;
}

.imgContainer img {
  height: 150px;
  /* width: 100%; */
}

.expandContainer {
  border-left: 6px solid #7965ab;
}
