.subContainer {
  padding: 120px 30px 40px;
  background-image: url(../../assets/icon/backgroundfull.png);
  background-size: cover;
  height: 100vh;
}

.contactLOGO {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

.contactLOGO h1 {
  font-weight: bold;
  margin-bottom: 10px;
}

.contactLOGO img {
  height: 70px;
}

.footerForm {
  background-color: #f1f0f7;
  padding: 20px;
  border-radius: 10px;
  width: 35%;
  margin: auto;
}

.footerForm h3 {
  font-size: 18px;
  color: #7965ab;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.footerForm input,
.footerForm textarea {
  margin-bottom: 20px;
  padding: 6px 10px;
  outline: none;
  border-radius: 5px;
  border: none;
  width: 100%;
  resize: none;
  border: 1px solid #7965ab4f;
}

.footerForm input::placeholder,
.footerForm textarea::placeholder {
  font-size: 15px;
}

.footerForm textarea {
  margin-bottom: 10px;
  height: 120px;
}

.footerForm .btn {
  background-color: #7965ab;
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 7px;
  color: #f1f1f1;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin: auto;
  display: block;
}
