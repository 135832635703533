.plans {
  padding: 80px 35px 100px;
  background-image: url(../../assets/icon/backgroundfull.png);
  background-size: cover;
}

.plans h3 {
  text-align: center;
  font-weight: bold;
  font-size: 44px;
}

.plans p {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #525252;
  letter-spacing: 0.1px;
}

/* ************************plansContent************************  */
.plansContent {
  border: 1px solid #7965ab;
  /* border-top: 0; */
  border-radius: 10px;
  overflow: hidden;
  margin-top: 70px;
  opacity: 0.8;
  transition: 0.3s;
  /* height: 910px; */
}

/* .plansContent:hover {
  box-shadow: 0px 0px 20px 0px rgb(224, 224, 224);
  opacity: 1;
  transform: translateY(-30px);
} */

/* .active {
  transform: translatey(-30px);
  box-shadow: 4px 7px 10px 0px #e8e8e8;
  border: 1px solid #7965ab;
  border-top: none;
  opacity: 1;
} */

.plansContent2 {
  position: relative;
}

.popular {
  position: absolute;
  top: 22px;
  right: -42px;
  transform: rotate(45deg);
  color: #fff;
  background-color: #da453e;
  font-size: 14px;
  padding: 0px 43px;
  text-transform: uppercase;
}

/* .active button {
  background-color: #7965ab !important;
  color: #fff !important;
} */

.plansContent .a {
  transition: 0.3s;
}

.plansContent:hover .a {
  color: #fff !important;
  background-color: #7965ab !important;
}

/* .plansContent h1 {
  padding: 24px 0px;
  text-align: center;
  background: #7965ab;
  color: #fff;
  font-size: 26px;
  letter-spacing: 0.3px;
} */

.plansContent span {
  display: block;
  text-align: center;
  font-size: 20px;
  margin: 15px 0px;
  font-weight: bold;
}

.plansContent .Exclamation {
  font-size: 16px;
  border: 2px solid rgb(165, 165, 165);
  border-radius: 50%;
  display: inline;
  padding: 0px 9px;
  width: 100% !important;
  margin-left: 5px;
}

.plansContent .a {
  display: block;
  width: 90%;
  margin: 20px auto;
  margin-top: 40px;
  background: none;
  border: 2px solid #7965ab;
  color: #7965ab;
  padding: 5px 0px;
  font-weight: 700;
  /* margin-bottom: 40px; */
  text-align: center;
}

.plansContent ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  border-top: 2px solid #acacac;
}

.plansContent ul li {
  margin: 17px 0px;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #505050;
  font-size: 14px;
}

.check,
.close {
  color: rgb(1, 209, 1);
  font-size: 20px;
  margin-right: 6px;
}

.close {
  color: #888;
}
/* ************************plansContent************************  */

/* **************************** START MEDIA QUERY ******************************* */
@media (max-width: 992px) {
  .plans {
    padding: 80px 5px 100px;
  }
}

/* **************************** END MEDIA QUERY ******************************* */
/* .Exclamation {
  position: relative;
} */

/* .Exclamation::before {
  content: "*includes setup fees plus to the customisation service cost.";
  position: absolute;
  display: block;
  top: -125px;
  left: -250px;
  padding: 10px 20px;
  opacity: 0;
  transition: 0.3s;
  background-color: #505050;
  color: #fff;
  border-radius: 10px;
}

.Exclamation:hover::before {
  opacity: 1;
} */

.priceContainer {
  display: flex;
  /* align-items: flex-start; */
  justify-content: space-evenly;
}

.priceContainer p {
  margin: 0 !important;
  padding: 0 !important;
}

.price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.price p {
  font-weight: bold;
  /* margin: 3px 0px !important; */
}

.price5 {
  position: relative;
}

.price5::before {
  content: "+";
  position: absolute;
  top: 0;
  left: -18px;
}

.priceContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3px;
}

.priceContent p {
  font-weight: 500;
  font-size: 16px;
  /* margin: 2px 0px !important; */
}

.plansContent p:nth-child(1) {
  padding-bottom: 10px !important;
}

.planUl {
  height: 500px;
  overflow: hidden;
  opacity: 0;
  height: 0;
  margin: 0 20px !important;
}

.planUlOpen {
  opacity: 1;
  height: 100%;
  transition: .5s;
}

.plansContent:hover .planUl {
  overflow: auto;
}

.planUl li {
  border-bottom: 2px solid #EEB3C3;
}

.a {
  text-decoration: none;
}

.plansContent:hover .a {
  color: #fff;
}

/* ALAA EDIT */
.namePlane h1 {
  background: none;
  color: #262152;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
  margin: 10px 0px;
  font-size: 24px;
}

.border {
  width: 80%;
  height: 2px;
  background-color: #262152;
  margin: auto;
  margin-bottom: 10px;
}

.allDetails {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.borderBottom {
  width: 90%;
  height: 2px;
  margin: 10px auto;
  background: #262152;
}

.month,
.or,
.year {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.month h6,
.year h6 {
  margin: 0;
  color: #da453e;
  font-weight: bold;
  font-size: 22px;
}

.month span,
.or span,
.year span {
  margin: 0;
  color: #262152;
  font-size: 18px;
}

.year {
  margin-top: 3px;
}

.or span:last-of-type {
  color: #da453e;
}

.seeFeaturesPlan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.seeFeaturesPlan button{
  border: none;
  outline: none;
  background: none;
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
  color: #262152;
  margin: 0px 10px;
}

.seeFeaturesPlan .subscribe{
  text-decoration: none;
  background-color: #7865AB;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 18px;
}